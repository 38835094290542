export interface DraperyOrderSummary {
    id: number;
    planningJson: DraperyOrderSummaryPlanningJson;
    estimateJson: DraperyOrderSummaryEstimateJson;
    invoiceJson: DraperyOrderSummaryInvoiceJson;
    productionJson: DraperyOrderSummaryProductionJson;
    installationJson: DraperyOrderSummaryInstallationJson;
    componentsJson: DraperyOrderSummaryComponentsJson;
    notes?: string | null | undefined;
    totalPerUnit?: number | null 
}

export interface ChangeableDraperyOrderSummary {
    planningJson?: DraperyOrderSummaryPlanningJson;
    estimateJson?: DraperyOrderSummaryEstimateJson;
    invoiceJson?: DraperyOrderSummaryInvoiceJson;
    productionJson?: DraperyOrderSummaryProductionJson;
    installationJson?: DraperyOrderSummaryInstallationJson;
    componentsJson?: DraperyOrderSummaryComponentsJson;
    details?: string | null;
    notes?: string | null | undefined;
    totalPerUnit?: number | null
}

export interface DraperyOrderSummaryPlanningJson {
    /*
     * Design
     */
    isDesign: boolean;

    /*
     * Fabrics
     */
    isFabrics: boolean;

    /*
     * Measurements
     */
    isMeasurements: boolean;

    /*
     * Hardware
     */
    isHardware: boolean;
}

export interface DraperyOrderSummaryEstimateJson {
    /*
     * Number
     */
    num?: string;

    /*
     * Description
     */
    description?: string;

    /*
     * Date Sent
     */
    dateSent?: string | Date | null;
}

export interface DraperyOrderSummaryInvoiceJson {
    /*
     * Number
     */
    num?: string;

    /*
     * Description
     */
    description?: string;

    /*
     * Paid
     */
    paid?: string;
}

export interface DraperyOrderSummaryProductionJson {
    /*
     * Start Date
     */
    startDate?: string | Date | null;

    /*
     * Due Date
     */
    dueDate?: string | Date | null;

    /*
     * Finished Date
     */
    finishedDate?: string | Date | null;
}

export interface DraperyOrderSummaryInstallationJson {
    /*
     * Order #
     */
    orderNum?: string;

    /*
     * Date of Insta
     */
    dateOfInstall?: string | Date | null;
}

export interface DraperyOrderSummaryComponentsJson {
    /*
     * Ordered
     */
    ordered?: string;

    /*
     * Received
     */
    received?: string;
}

export const defaultDraperyOrderSummaryPlanningJson: DraperyOrderSummaryPlanningJson =
    {
        isDesign: false,
        isFabrics: false,
        isMeasurements: false,
        isHardware: false,
    };

export const defaultDraperyOrderSummaryEstimateJson: DraperyOrderSummaryEstimateJson =
    {
        num: undefined,
        description: undefined,
        dateSent: undefined,
    };

export const defaultDraperyOrderSummaryInvoiceJson: DraperyOrderSummaryInvoiceJson =
    {
        num: undefined,
        description: undefined,
        paid: undefined,
    };

export const defaultDraperyOrderSummaryProductionJson: DraperyOrderSummaryProductionJson =
    {
        startDate: undefined,
        dueDate: undefined,
        finishedDate: undefined,
    };

export const defaultDraperyOrderSummaryInstallationJson: DraperyOrderSummaryInstallationJson =
    {
        orderNum: undefined,
        dateOfInstall: undefined,
    };

export const defaultDraperyOrderSummaryComponentsJson: DraperyOrderSummaryComponentsJson =
    {
        ordered: undefined,
        received: undefined,
    };
