import {
    Button,
    FormField,
    FormValidatorErrorMap,
    Modal,
    ModalActions,
    ModalCloseIcon,
    ModalContent,
    ModalHeader,
    ValidationForm,
    ValidationPopupInput,
    ValidityMessagesMap,
} from "@ramble/ramble-ui";
import Bind from "lodash-decorators/bind";
import React, { FormEvent, PureComponent } from "react";
import { FaLock } from "react-icons/fa";
import styled from "../theme";

interface UpdatePasswordModalProps {
    /**
     * Modal is active
     */
    active: boolean;
    /**
     * Update password callback
     * @param oldPass
     * @param newPass
     */
    onUpdatePassword(oldPass: string, newPass: string): Promise<void>;
    /**
     * Request close callback
     */
    onRequestClose(): void;
    /**
     * Validation messages
     */
    validityMessages: ValidityMessagesMap<"password">;
    validityMessageOldPass: ValidityMessagesMap<"password">;
    /**
     * Set validation message
     */
    handleSetValidationMessage(value: string): void;
    handleSetValidationMessageOldPass(value: string): void;
    /**
     * Validate incorrect old password
     */
    validateError: boolean;
    handleSetValidateError(val: boolean): void;
}

const PasswordField = styled(FormField) `
    & > .label {
        flex: 1 0 150px;
        font-size: 14px;
        font-weight: 600;
    }

    & > .control {
        flex: 3 1 250px;
    }
`;
const BasicButton = styled(Button)`
    // width: 130px;
    box-shadow: none;
    border: 1px solid #c6c6c6;
    border-radius: 0.5em;
    display: block;
`;

const CancelButton = styled(BasicButton)`
    border-radius: 5px;
    // width: 100px;
    box-shadow: none;
    line-height: 0.6rem;
    background-color: #f6f6f6;
    color: #222222;
    border: none;
    border: 1px solid #cdcdcd;
 
    &:hover {
        background: #eeeeee;
    }
`;

interface State {
    updating: boolean;
}

export class UpdatePasswordModal extends PureComponent<UpdatePasswordModalProps, State> {
    public state: State = {
        updating: false,
    };

    componentDidUpdate(prevProps: Readonly<UpdatePasswordModalProps>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.validateError !== this.props.validateError) {
            if (this.props.validateError) {
                const elem = document.getElementById('updateBtn')
                if (elem) {
                    elem.click()
                    this.props.handleSetValidateError(false)
                }
            }
        }
    }

    public render(): JSX.Element {
        const { active } = this.props;
        return (
            <Modal
                uiActive={active}
                uiOnRequestClose={this.handleClose}
            >
                <ModalHeader>
                    <FaLock />
                    Update your password
                    <ModalCloseIcon onClick={this.handleClose} />
                </ModalHeader>
                <ModalContent>
                    <ValidationForm
                        uiReportValidityMode="firstInvalid"
                        uiUseSubmitFailedMessageFromError
                        uiPadding={false}
                        id="passwordform"
                        autoComplete="off"
                        uiOnSubmit={this.handleUpdate}
                        uiFormValidator={this.formValidator}
                    >
                        <PasswordField
                            uiRequired
                            uiErrorAlignment="left"
                            uiLabel="Current Password"
                            uiInline
                        >
                            <ValidationPopupInput
                                id="oldpass"
                                name="oldpass"
                                type="password"
                                autoFocus
                                required
                                tabIndex={10}
                                minLength={8}
                                maxLength={255}
                                // uppercase, lowercase, number/special chars and min 8 chars
                                pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                                placeholder="Old Password"
                                uiReportOnBlur={false}
                                uiPopupPosition="top right"
                                uiValidityMessages={this.props.validityMessageOldPass.password}
                            />
                        </PasswordField>
                        <PasswordField
                            uiRequired
                            uiErrorAlignment="left"
                            uiLabel="New Password"
                            uiInline
                        >
                            <ValidationPopupInput
                                id="newpass"
                                name="newpass"
                                type="password"
                                required
                                tabIndex={11}
                                minLength={8}
                                maxLength={255}
                                // uppercase, lowercase, number/special chars and min 8 chars
                                pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                                placeholder="New Password"
                                uiReportOnBlur={false}
                                uiPopupPosition="top right"
                                uiValidityMessages={this.props.validityMessages.password}
                            />
                        </PasswordField>
                        <PasswordField
                            uiRequired
                            uiErrorAlignment="left"
                            uiLabel="Confirm Password"
                            uiInline
                        >
                            <ValidationPopupInput
                                id="newpass_repeat"
                                name="newpass_repeat"
                                type="password"
                                required
                                tabIndex={12}
                                minLength={8}
                                maxLength={255}
                                // uppercase, lowercase, number/special chars and min 8 chars
                                pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                                placeholder="Confirm Password"
                                uiReportOnBlur={false}
                                uiPopupPosition="top right"
                                uiValidityMessages={this.props.validityMessages.password}
                            />
                        </PasswordField>
                    </ValidationForm>
                </ModalContent>
                <ModalActions style={{ justifyContent: "space-between" }}>
                    <CancelButton
                        uiColor="secondary"
                        uiDisabled={this.state.updating}
                        onClick={this.handleClose}
                    >
                        Cancel
                    </CancelButton>
                    <Button
                        form="passwordform"
                        uiColor="primary"
                        uiDisabled={this.state.updating}
                    >
                        Update
                    </Button>
                </ModalActions>
            </Modal>
        );
    }

    @Bind()
    private handleClose(): void {
        if (this.state.updating) {
            return;
        }
        this.props.onRequestClose();
    }

    @Bind()
    private async handleUpdate(e: FormEvent<HTMLFormElement>): Promise<void> {
        if (this.state.updating) {
            return;
        }
        this.setState({
            updating: true,
        });
        const elem = e.currentTarget;
        /* tslint:disable:no-string-literal */
        const newPassword = (elem.elements["newpass"] as HTMLInputElement).value;
        const oldPassword = (elem.elements["oldpass"] as HTMLInputElement).value;

        try {
            await this.props.onUpdatePassword(oldPassword, newPassword);
            this.props.onRequestClose();
        } finally {
            this.setState({
                updating: false,
            });
        }
    }

    @Bind()
    private formValidator(elem?: HTMLFormElement): FormValidatorErrorMap {
        if (this.props.validateError) {
            this.props.handleSetValidationMessageOldPass('Current password is incorrect.')
            return {
                oldpass: 'Current password is incorrect.'
            }
        }
        // tslint:disable-next-line:no-string-literal
        const password = (elem && elem.elements && elem.elements["newpass"] && elem.elements["newpass"] as HTMLInputElement).value;
        // tslint:disable-next-line:no-string-literal
        const repeatPassword = (elem && elem.elements && elem.elements["newpass_repeat"] && elem.elements["newpass_repeat"] as HTMLInputElement).value;
        // tslint:disable-next-line:no-string-literal
        if (password !== repeatPassword) {
            const errorMsg = 'Confirm Password must match New Password.'
            this.props.handleSetValidationMessage(errorMsg)
            return {
                newpass_repeat: "Passwords must match",
            };
        }
        return {};
    }
}
